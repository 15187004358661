<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-toolbar dense flat>
            <v-spacer/>
            <base-notification
              @refresh="$emit('change')"
              v-if="record.person && record.person.user"
              :user_id="record.person.user.id"
              :recipient="record.person.name"
              :phonenumber="record.person.mobilenumber"/>
            <v-btn :disabled="loading" :loading="loading" class="ma-3" small @click="exportDossier('pdf')" color="primary">
              {{ $store.getters.translate("export_to_pdf") }}
              <v-icon right x-small>mdi-download</v-icon>
            </v-btn>
            <v-btn :disabled="loading" :loading="loading" class="ma-5" small @click="exportDossier('excel')" color="primary">
              {{ $store.getters.translate("export_to_excel") }}
              <v-icon right x-small>mdi-download</v-icon>
            </v-btn>
            <v-btn v-if="canDelete" @click="archive" text small color="secondary">
              <v-icon color="error">mdi-archive</v-icon>
            </v-btn>
            <v-btn v-if="canEdit" text small @click="save" color="primary">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider/>
          <v-card-text>
            <stepper :dossier="record" :re_examination="re_examination" :exam_id="last_exam_id" @document_preview="preview" @refresh="load" @change="editCertificate"/>
            <div style="margin: 25px">
              <base-row
                v-if="record.scheme"
                :label="$store.getters.translate('scheme')"
                :value="record.scheme.name"
                :link="true"
                @click="$router.push('/schemes/' + record.scheme.id)"/>
              <base-row
                v-if="record.root_exam"
                :label="$store.getters.translate('root_exam')"
                :value="record.root_exam.name"
                :link="true"
                @click="$router.push('/exams/' + record.root_exam.id)"/>
              <base-row
                v-if="record.candidate"
                :label="$store.getters.translate('candidate')"
                :value="record.candidate.name"
                :link="true"
                @click="$router.push('/candidates/' + record.candidate.id)"/>
              <v-text-field
                :label="$store.getters.translate('agreed_with_terms_on')"
                v-model="record.agreed_with_terms_on"
                v-mask="'##-##-####'"
                placeholder="dd-mm-yyyy"
                clearable/>
              <v-switch
                  :disabled="!$store.getters.getUser.is_admin"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('closed')"
                  v-model="record.is_closed"/>
              <base-log-field :model_type="$models[page.name]" :model_id="record.id" :related_model_type="'Candidate'" :related_model_id="record.candidate.id"/>
              <base-tags v-model="record.tags" :module_name="page.name"/>
              <template v-for="field in custom_fields_names">
                <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="fieldDataChanged"/>
              </template>
            </div>
          </v-card-text>
          <v-footer-panel ref="footer_panel" :model="page.name"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="exams_panel">
          <template v-for="(record_result, key) in record_results">
            <v-expansion-panel v-if="!record.exams[key].re_examination || record.exams.length === 1" :key="key">
              <v-expansion-panel-header>
                <v-toolbar class="no-left-padding" dense flat>
                  <v-icon class="mr-2">{{ $icons['exams'] }}</v-icon>
                  <v-toolbar-title style="font-size: 13pt" class="ma-0 pa-0">
                    Examen: {{ record.exams[key].name }} ({{ record.exams[key].examination_date }})
                  </v-toolbar-title>
                  <v-spacer/>
                </v-toolbar>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <base-exam
                    :record_result="record_result"
                    :record="record"
                    @refresh="load"
                    @createCertificate="createCertificate"
                    @editCertificate="editCertificate"
                    :index="key"/>
                <div v-for="(record_result_2, key2) in record_results" :key="key2">
                  <template v-if="record.exams[key2].re_examination && record.exams[key2].re_exam_id === record.exams[key].id">
                    <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                    <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key2].name }} ({{ record.exams[key2].examination_date }})</span>
                    <base-exam
                        :record_result="record_result_2"
                        :record="record"
                        @refresh="load"
                        @createCertificate="createCertificate"
                        @editCertificate="editCertificate"
                        :index="key2"/>
                    <div v-for="(record_result_3, key3) in record_results" :key="key3">
                      <template v-if="record.exams[key3].re_examination && record.exams[key3].re_exam_id === record.exams[key2].id">
                        <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                        <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key3].name }} ({{ record.exams[key3].examination_date }})</span>
                        <base-exam
                            :record_result="record_result_3"
                            :record="record"
                            @refresh="load"
                            @createCertificate="createCertificate"
                            @editCertificate="editCertificate"
                            :index="key3"/>
                        <div v-for="(record_result_4, key4) in record_results" :key="key4">
                          <template v-if="record.exams[key4].re_examination && record.exams[key4].re_exam_id === record.exams[key3].id">
                            <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                            <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key4].name }} ({{ record.exams[key4].examination_date }})</span>
                            <base-exam
                                :record_result="record_result_4"
                                :record="record"
                                @refresh="load"
                                @createCertificate="createCertificate"
                                @editCertificate="editCertificate"
                                :index="key4"/>
                            <div v-for="(record_result_5, key5) in record_results" :key="key5">
                              <template v-if="record.exams[key5].re_examination && record.exams[key5].re_exam_id === record.exams[key4].id">
                                <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key5].name }} ({{ record.exams[key5].examination_date }})</span>
                                <base-exam
                                    :record_result="record_result_5"
                                    :record="record"
                                    @refresh="load"
                                    @createCertificate="createCertificate"
                                    @editCertificate="editCertificate"
                                    :index="key5"/>
                                <div v-for="(record_result_6, key6) in record_results" :key="key6">
                                  <template v-if="record.exams[key6].re_examination && record.exams[key6].re_exam_id === record.exams[key5].id">
                                    <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                    <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key6].name }} ({{ record.exams[key6].examination_date }})</span>
                                    <base-exam
                                        :record_result="record_result_6"
                                        :record="record"
                                        @refresh="load"
                                        @createCertificate="createCertificate"
                                        @editCertificate="editCertificate"
                                        :index="key6"/>
                                    <div v-for="(record_result_7, key7) in record_results" :key="key7">
                                      <template v-if="record.exams[key7].re_examination && record.exams[key7].re_exam_id === record.exams[key6].id">
                                        <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                        <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key7].name }} ({{ record.exams[key7].examination_date }})</span>
                                        <base-exam
                                            :record_result="record_result_7"
                                            :record="record"
                                            @refresh="load"
                                            @createCertificate="createCertificate"
                                            @editCertificate="editCertificate"
                                            :index="key7"/>
                                        <div v-for="(record_result_8, key8) in record_results" :key="key8">
                                          <template v-if="record.exams[key8].re_examination && record.exams[key8].re_exam_id === record.exams[key7].id">
                                            <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                            <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key8].name }} ({{ record.exams[key8].examination_date }})</span>
                                            <base-exam
                                                :record_result="record_result_8"
                                                :record="record"
                                                @refresh="load"
                                                @createCertificate="createCertificate"
                                                @editCertificate="editCertificate"
                                                :index="key8"/>
                                            <div v-for="(record_result_9, key9) in record_results" :key="key9">
                                              <template v-if="record.exams[key9].re_examination && record.exams[key9].re_exam_id === record.exams[key8].id">
                                                <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                                <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key9].name }} ({{ record.exams[key9].examination_date }})</span>
                                                <base-exam
                                                    :record_result="record_result_9"
                                                    :record="record"
                                                    @refresh="load"
                                                    @createCertificate="createCertificate"
                                                    @editCertificate="editCertificate"
                                                    :index="key9"/>
                                                <div v-for="(record_result_10, key10) in record_results" :key="key10">
                                                  <template v-if="record.exams[key10].re_examination && record.exams[key10].re_exam_id === record.exams[key9].id">
                                                    <v-icon class="ma-5">{{ $icons['exams'] }}</v-icon>
                                                    <span>{{ $store.getters.translate('re_examination') }}: {{ record.exams[key10].name }} ({{ record.exams[key10].examination_date }})</span>
                                                    <base-exam
                                                        :record_result="record_result_10"
                                                        :record="record"
                                                        @refresh="load"
                                                        @createCertificate="createCertificate"
                                                        @editCertificate="editCertificate"
                                                        :index="key10"/>
                                                  </template>
                                                </div>
                                              </template>
                                            </div>
                                          </template>
                                        </div>
                                      </template>
                                    </div>
                                  </template>
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
                <v-row style="text-align: left">
                  <v-col>
                    <v-btn @click="createCertificate(record.exams[record.exams.length-1])" text small color="primary">
                      {{ $store.getters.translate('create_certificate') }}
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="notes_panel">
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"/>
    <edit-certificate modal_name="edit_certificate" :dossier="record" ref="edit_certificate" @refresh="load"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load"/>
    <create-certificate modal_name="attach_certificate" :dossier="record" ref="attach_certificate" @refresh="load" @change="editCertificate"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import EditNote from "../notes/Edit";
import EditCertificate from "../certificates/Edit";
import Stepper from "./includables/Stepper";
import CreateNote from "../notes/Create";
import CreateCertificate from "../certificates/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
import BaseExam from "../../components/commonComponents/BaseExam";
import helpFunctions from "../../plugins/helpFunctions";
import moment from "moment";

export default {
  components: {
    BaseExam,
    DocumentEdit,
    BaseTableIncludable,
    CreateCertificate,
    EditCertificate,
    EditNote,
    CreateNote,
    Stepper,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("dossiers"),
        name: "dossiers",
        model: "dossier",
      },
      record: {
        name: "",
        candidate: {},
      },
      re_examination: false,
      last_exam_id: null,
      record_results: [],
      loading: false,
      key: 0,
      custom_fields_names: [],
      exams_panel: 0,
      notes_panel: 0
    };
  },
  created() {
    this.load();
    this.getCustomFields();
  },
  methods: {
    load() {
      this.key = this.key + 1;
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          if (this.record.exams && this.record.exams.length > 0) {
            //SORT EXAMS FIRST
            this.record.exams = this.record.exams.sort((a, b) => {
              a = moment(a["examination_date"], "DD-MM-YYYY").toDate();
              b = moment(b["examination_date"], "DD-MM-YYYY").toDate();
              return a - b;
            });
            //FIND LAST EXAM ID
            this.re_examination = this.record.exams[this.record.exams.length-1].re_examination;
            this.last_exam_id = this.record.exams[this.record.exams.length-1].id;
            this.record_results = [];
            //var parent_exam_counter = 0;
            this.record.exams = helpFunctions.getLocationsAndRooms(this.record.exams, true, true);
            for (let i = 0; i < this.record.exams.length; i++) {
              //if (!this.record.exams[i].re_examination) {
              //  parent_exam_counter++;
              //}
              //CUSTOM FIX FOR ITV. ADDING PARENT EXAM TO USUAL EXAMS THAT ARE RE-EXAMS BUT MISTAKENLY CREATED AS USUAL EXAMS
              if(i > 0 && !this.record.exams[i].re_examination) {
                this.record.exams[i].re_examination = true;
                this.record.exams[i].re_exam_id = this.record.exams[i-1].id;
                this.record.exams[i].parent = this.record.exams[i-1];
              }
              var temp = {
                id: this.record.id,
                scheme: this.record.scheme,
                scheme_id: this.record.scheme_id,
                candidate_id: this.record.candidate_id,
                exam: this.record.exams[i],
                results: []
              };
              for (let x = 0; x < this.record.results.length; x++) {
                if(this.record.results[x].session != null && this.record.results[x].session.exam_id === this.record.exams[i].id) {
                  temp.results.push(this.record.results[x]);
                }
              }
              this.record_results.push(temp);
            }
            //this.exams_panel = parent_exam_counter - 1;
          }
          this.updateIncludableTables();
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.notes_table.selected = [];
      this.$refs.footer_panel.record = this.record;
      this.$refs.footer_panel.load();
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.load();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    exportDossier(export_type) {
      this.loading = true;
      let html = helpFunctions.exportDossier(export_type, this.record, this.record_results);
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.record.name}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.record.name + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.record.name + ".xls";
      link.click();
      this.loading = false;
    },
    getCustomFields() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/fields?model_type=Dossier")
        .then((response) => {
          this.custom_fields_names = [];
          response.data.forEach((field) => {
            if (field.custom_field == 1) {
              this.custom_fields_names.push(field);
            }
          });
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
    },
    fieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    createCertificate(item) {
      if(!this.checkStatus('passed_all_sessions') || !this.checkStatus('certifiable')) {
        this.$toasted.error(this.$store.getters.translate("not_passed_sessions"));
      }
      else {
        if (this.record.certificates.length) {
          var certificate_exists = false;
          for (let x = 0; x < this.record.certificates.length; x++) {
            if (this.record.certificates[x].exam_id === item.id) {
              certificate_exists = true;
              break;
            }
          }
          if (certificate_exists) {
            this.$confirm(this.$store.getters.translate("add_additional_certificate")).then((res) => {
              if (res === true) {
                this.$refs.attach_certificate.exam_id = item.id;
                this.$refs.attach_certificate.openModal('attach_certificate');
              }
            })
          } else {
            this.$refs.attach_certificate.exam_id = item.id;
            this.$refs.attach_certificate.openModal('attach_certificate')
          }
        } else {
          this.$refs.attach_certificate.exam_id = item.id;
          this.$refs.attach_certificate.openModal('attach_certificate')
        }
      }
    },
    editCertificate(item) {
      this.$refs.edit_certificate.id = item.id;
      this.$refs.edit_certificate.openModal('edit_certificate');
      this.$refs.edit_certificate.load();
    },
    checkStatus(status) {
      if (!this.record.statuses) {
        return false;
      }
      let exists = this.record.statuses.find((status_node) => status_node.name === status);
      if (exists) {
        return true;
      }
      return false;
    },
    canEdit() {
      return this.$can("edit", this.page.model);
    },
    canDelete() {
      return this.$can("delete", this.page.model);
    },
  },
};
</script>